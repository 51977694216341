import React from 'react';
import { useIntl } from 'react-intl';
import Chart from '../../style/img/pic/chart-min.png';
import Ring_1 from '../../style/img/svg/ring-1.svg';
import Ring_2 from '../../style/img/svg/ring-2.svg';

function Tokenomics(): JSX.Element {
  const intl = useIntl();
  return (
    <div className="container">
      <div className="distribution" id="distribution">
        <h2 className="distribution__title">{intl.formatMessage({ id: 'TOKENOMICS_TITLE' })}</h2>
        <div className="distribution__wrap">
          <div className="distribution-item">
            <div className="distribution-item__ring">
              <img src={Ring_1} alt="" />
              <div className="distribution-item__title">
                {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TITLE_ONE' })} <span>25 000 000</span>
              </div>
            </div>
            <div className="distribution-item__left">
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_FARMING' })}
                </div>
                <div className="distribution-item__legend-val">
                  8 750 000 <span>35%</span>
                </div>
              </div>
            </div>
            <div className="distribution-item__right distribution-item__right--1">
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TREASURY' })}
                </div>
                <div className="distribution-item__legend-val">
                  1 500 000 <span>6%</span>
                </div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {' '}
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TEAM' })}
                </div>
                <div className="distribution-item__legend-val">
                  3 000 000 <span>12%</span>
                </div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_PRIVATE_SALE' })}
                </div>
                <div className="distribution-item__legend-val">
                  3 000 000 <span>12%</span>
                </div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_LIQUIDITY' })}
                </div>
                <div className="distribution-item__legend-val">
                  8 750 000 <span>35%</span>
                </div>
              </div>
            </div>
          </div>
          <div className="distribution-item">
            <div className="distribution-item__ring">
              <img src={Ring_2} alt="" />
              <div className="distribution-item__title">
                {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TITLE_TWO' })} <span>150 000 000</span>
              </div>
            </div>
            <div className="distribution-item__right distribution-item__right--2">
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {' '}
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TREASURY' })}
                </div>
                <div className="distribution-item__legend-val">6%</div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_TEAM' })}
                </div>
                <div className="distribution-item__legend-val">6%</div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_LIQUIDITY' })}
                </div>
                <div className="distribution-item__legend-val">15%</div>
              </div>
              <div className="distribution-item__legend">
                <div className="distribution-item__legend-title">
                  {' '}
                  {intl.formatMessage({ id: 'TOKENOMICS_DISTRIBUTION_ITEM_FARMING' })}
                </div>
                <div className="distribution-item__legend-val">73%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="distribution__graphic">
          <h3 className="distribution__graphic-title">{intl.formatMessage({ id: 'TOKENOMICS_GRAPHIC_TITLE' })}</h3>
          <div className="distribution-graphic">
            <div className="distribution-graphic__y">
              <span>100%</span>
              <span>75%</span>
              <span>50%</span>
              <span>25%</span>
              <span>17%</span>
              <span>5%</span>
              <div className="distribution-graphic__y-title">
                {intl.formatMessage({ id: 'TOKENOMICS_GRAPHIC_Y_TITLE' })}
              </div>
            </div>
            <div className="distribution-graphic__x">
              <span>1</span>
              <span>6</span>
              <span>12</span>
              <span>18</span>
              <span>24</span>
              <span>30</span>
              <span>36</span>
              <span>42</span>
              <div className="distribution-graphic__x-title">
                {intl.formatMessage({ id: 'TOKENOMICS_GRAPHIC_X_TITLE' })}
              </div>
            </div>
            <div className="distribution-graphic__main">
              <span />
              <span />
              <span />
              <span />
              <span />
              <img src={Chart} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;

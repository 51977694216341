import React from 'react';
import './App.css';
import './style/css/style.css';
import LanguageProvider from './providers/LanguageProvider';
import Main from './Main';
import MobileProvider from './providers/MobileProvider';

function App(): JSX.Element {
  return (
    <MobileProvider>
      <LanguageProvider>
        <Main />
      </LanguageProvider>
    </MobileProvider>
  );
}

export default App;

import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../common/Icon';

function Icons(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="icons">
      <div className="container">
        <h2 className="sec-title">{intl.formatMessage({ id: 'ICONS_SECTION_TITLE' })}</h2>
        <div className="icons-list icons-list--4">
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn1" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_FIRST_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'ICONS_FIRST_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn2" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_SECOND_ITEM_TITLE' })}</h3>
            <p
              className="icons-item__txt"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'ICONS_SECOND_ITEM_TEXT',
                }),
              }}
            />
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn3" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_THIRD_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'ICONS_THIRD_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn4" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_FOURTH_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'ICONS_FOURTH_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn5" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_FIFTH_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'ICONS_FIFTH_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn6" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_SIXTH_ITEM_TITLE' })}</h3>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn7" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_SEVENTH_ITEM_TITLE' })}</h3>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn8" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'ICONS_EIGHTH_ITEM_TITLE' })}</h3>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Icons;

import React from 'react';
import { useIntl } from 'react-intl';
import Logo from '../header/Logo';
import { Icon } from '../common/Icon';
import { links } from '../../constants/links';

function Footer(): JSX.Element {
  const intl = useIntl();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrap">
          <div className="footer__logo">
            <Logo />
          </div>
          <nav className="footer-menu">
            <ul>
              <li>
                <a target="_blank" href={links.coingecko} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_COINGECKO_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.everWallet} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_EVER_WALLET_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.adaever} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_BRIDGE_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.octus} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_OCTUS_BRIDGE_LINK_TEXT' })}
                </a>
              </li>
            </ul>
          </nav>
          <ul className="soc soc--footer">
            <li>
              <a href={links.youTube} target="_blank" rel="noreferrer">
                <Icon icon="youTubeBlack" />
              </a>
            </li>
            <li>
              <a href={links.twitter} target="_blank" rel="noreferrer">
                <Icon icon="twitterBlack" />
              </a>
            </li>
            <li>
              <a href={links.medium} target="_blank" rel="noreferrer">
                <Icon icon="mediumBlack" />
              </a>
            </li>
            <li>
              <a href={links.telegram} target="_blank" rel="noreferrer">
                <Icon icon="telegramBlack" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

import React from 'react';
import { DropdownItem } from './DropdownItem';

type TDropdown = {
  items: string[];
  onCLick: () => void;
  isOpen: boolean;
};
function Dropdown({ items, onCLick, isOpen }: TDropdown): JSX.Element | null {
  if (!isOpen) return null;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div className="lang__drop" style={{ zIndex: 2000 }}>
      <ul>
        {items.map((item) => (
          <DropdownItem item={item} onClick={onCLick} key={item} />
        ))}
      </ul>
    </div>
  );
}

export default Dropdown;

export const links = {
  telegram: 'https://t.me/DexadaCommunity',
  youTube: 'https://www.youtube.com/channel/UC3xAdlyTH0zbDNDRd3zhVlg/about',
  twitter: 'https://twitter.com/Dexada_io',
  medium: 'https://medium.com/dexada',
  coingecko: 'https://www.coingecko.com/en/coins/everscale',
  everWallet: 'https://l1.broxus.com/',
  adaever: 'https://adaever.io/',
  dexada: 'https://app.dexada.io/',
  octus: 'https://app.octusbridge.io/',
};

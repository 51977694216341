/* eslint-disable max-len */
import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../common/Icon';

function Faq(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="faq" id="faq">
      <div className="container">
        <div className="faq__header">
          <h2 className="sec-title">{intl.formatMessage({ id: 'FAQ_TITLE' })}</h2>
        </div>
        <div className="faq__list">
          <a
            href="https://medium.com/dexada/answers-to-the-frequently-asked-questions-about-dexada-decentralized-exchange-729aafb4deec"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span>{intl.formatMessage({ id: 'QUESTION_ONE' })}</span>
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
          <a
            href="https://medium.com/dexada/how-to-add-tokens-to-display-in-my-ever-wallet-cbe63630afb"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span>{intl.formatMessage({ id: 'QUESTION_TWO' })}</span>
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
          <a
            href="https://medium.com/dexada/what-is-a-wton-and-how-can-i-get-it-1642b6b4ee08"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span>{intl.formatMessage({ id: 'QUESTION_THREE' })}</span>
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
          <a
            href="https://medium.com/dexada/how-do-i-start-farming-aafd2c40d88a"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span>{intl.formatMessage({ id: 'QUESTION_FOUR' })}</span>
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
          <a
            href="https://medium.com/dexada/where-can-i-see-the-value-of-my-lp-tokens-c3df1dffab82"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span>{intl.formatMessage({ id: 'QUESTION_FIVE' })}</span>
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
          <a
            href="https://medium.com/dexada/when-will-i-get-my-farming-rewards-a5f5142c6a8a"
            target="_blank"
            className="faq-item"
            rel="noreferrer"
          >
            <span
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'QUESTION_SIX',
                }),
              }}
            />
            <i>
              <Icon icon="svgFaq" />
            </i>
          </a>
        </div>
      </div>
    </section>
  );
}

export default Faq;

import React from 'react';
import { useIntl } from 'react-intl';

function Interoperability(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="interoperability">
      <div className="container">
        <div className="interoperability__wrap">
          <div className="interoperability__container">
            <h2 className="sec-title">{intl.formatMessage({ id: 'INTEROPERABILITY_SECTION_TITLE' })}</h2>
          </div>
          <div className="interoperability__list">
            <div className="interoperability-item">
              <h3 className="interoperability-item__title">
                {intl.formatMessage({ id: 'INTEROPERABILITY_FIRST_ITEM_TITLE' })}
              </h3>
              <p className="interoperability-item__txt">
                {intl.formatMessage({ id: 'INTEROPERABILITY_FIRST_ITEM_TEXT' })}
              </p>
            </div>
            <div className="interoperability-item">
              <h3 className="interoperability-item__title">
                {intl.formatMessage({ id: 'INTEROPERABILITY_SECOND_ITEM_TITLE' })}
              </h3>
              <p className="interoperability-item__txt">
                {intl.formatMessage({ id: 'INTEROPERABILITY_SECOND_ITEM_TEXT' })}
              </p>
            </div>
            <div className="interoperability-item">
              <h3 className="interoperability-item__title">
                {intl.formatMessage({ id: 'INTEROPERABILITY_THIRD_ITEM_TITLE' })}
              </h3>
              <p className="interoperability-item__txt">
                {intl.formatMessage({ id: 'INTEROPERABILITY_THIRD_ITEM_TEXT' })}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Interoperability;

import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../common/Icon';

function Everscale(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="icons icons--2">
      <div className="container">
        <h2 className="sec-title">{intl.formatMessage({ id: 'EVERSCALE_SECTION_TITLE' })}</h2>
        <div className="icons-list icons-list--3">
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn10" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_FIRST_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'EVERSCALE_FIRST_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn11" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_SECOND_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'EVERSCALE_SECOND_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn12" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_THIRD_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'EVERSCALE_THIRD_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn13" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_FOUTH_ITEM_TITLE' })}</h3>
            <p
              className="icons-item__txt"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'EVERSCALE_FOUTH_ITEM_TEXT',
                }),
              }}
            />
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn4" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_FIFTH_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'EVERSCALE_FIFTH_ITEM_TEXT' })}</p>
          </div>
          <div className="icons-item">
            <div className="icons-item__icn">
              <Icon icon="icn4" />
            </div>
            <h3 className="icons-item__title">{intl.formatMessage({ id: 'EVERSCALE_SIXTH_ITEM_TITLE' })}</h3>
            <p className="icons-item__txt">{intl.formatMessage({ id: 'EVERSCALE_SIXTH_ITEM_TEXT' })}</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Everscale;

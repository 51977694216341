import { LOCALES } from './locales';
import enLocale from '../lang/en';
import koLocale from '../lang/ko';
import jaLocale from '../lang/ja';

export const messages = {
  [LOCALES.en]: enLocale,
  [LOCALES.한국어]: koLocale,
  [LOCALES.ja]: jaLocale,
};

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useContext } from 'react';
import { LanguageContext } from '../../../providers/LanguageProvider';

type TDropdownItem = {
  onClick: () => void;
  item: string;
};

export function DropdownItem({ onClick, item }: TDropdownItem) {
  const { setLang } = useContext(LanguageContext);

  const setLanguage = (value: string) => {
    setLang(value);
    onClick();
  };

  return (
    <li
      onClick={(event) => {
        event.stopPropagation();
        setLanguage(item);
        localStorage.setItem('lang', item);
      }}
      key={`${item}-item`}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="lang__item" style={{ textTransform: 'capitalize', cursor: 'pointer', zIndex: 2000 }}>
        {/* eslint-disable-next-line no-nested-ternary */}
        {item === 'ja' ? '日本語' : item === 'en' ? 'eng' : item}
      </a>
    </li>
  );
}

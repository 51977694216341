import React, { createContext, useState } from 'react';

type Props = { children?: React.ReactNode };
type AppContext = {
  isMobile: boolean;
  setIsMobile: (isMobile: boolean) => void;
};

export const MobileContext = createContext<AppContext>({
  isMobile: false,
  setIsMobile: () => undefined,
});

// eslint-disable-next-line react/function-component-definition
const MobileProvider: React.FC<Props> = ({ children }) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    isMobile,
    setIsMobile,
  };

  return <MobileContext.Provider value={contextValue}>{children}</MobileContext.Provider>;
};

export default MobileProvider;

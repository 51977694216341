import React, { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Logo from './Logo';
import { Icon } from '../common/Icon';
import { links } from '../../constants/links';
import Languages from '../languages/Languages';
import { MobileContext } from '../../providers/MobileProvider';
import useResize from '../../hooks/useResize';

function Header(): JSX.Element {
  const intl = useIntl();
  useResize();
  const { isMobile } = useContext(MobileContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  useEffect(() => {
    if (!isMobile) {
      setIsMobileMenuOpen(false);
    }
  }, [isMobile]);
  return (
    <>
      <header className="header">
        <div className="header__container">
          <div className="header__wrap">
            <div className="header__logo">
              {' '}
              <Logo />
            </div>
            {!isMobile ? (
              <div className="header__bar">
                <nav className="main-menu">
                  <ul>
                    <li>
                      <a href="#distribution">{intl.formatMessage({ id: 'HEADER_TOKENOMICS_LINK_TEXT' })}</a>
                    </li>
                    <li>
                      <a target="_blank" href={links.coingecko} rel="noreferrer">
                        {intl.formatMessage({ id: 'HEADER_COINGECKO_LINK_TEXT' })}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={links.everWallet} rel="noreferrer">
                        {intl.formatMessage({ id: 'HEADER_EVER_WALLET_LINK_TEXT' })}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={links.adaever} rel="noreferrer">
                        {intl.formatMessage({ id: 'HEADER_BRIDGE_LINK_TEXT' })}
                      </a>
                    </li>
                    <li>
                      <a target="_blank" href={links.octus} rel="noreferrer">
                        {intl.formatMessage({ id: 'HEADER_OCTUS_BRIDGE_LINK_TEXT' })}
                      </a>
                    </li>
                  </ul>
                </nav>
                <ul className="soc">
                  <li>
                    <a href={links.youTube} target="_blank" rel="noreferrer">
                      <Icon icon="youTube" />
                    </a>
                  </li>
                  <li>
                    <a href={links.twitter} target="_blank" rel="noreferrer">
                      <Icon icon="twitter" />
                    </a>
                  </li>
                  <li>
                    <a href={links.medium} target="_blank" rel="noreferrer">
                      <Icon icon="medium" />
                    </a>
                  </li>
                  <li>
                    <a href={links.telegram} target="_blank" rel="noreferrer">
                      <Icon icon="telegram" />
                    </a>
                  </li>
                </ul>
                <Languages />
                <a href={links.dexada} target="_blank" className="btn btn--primery btn--md" rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_BUTTON' })}
                </a>
              </div>
            ) : (
              <button
                className="btn header__burger"
                id="js-mob-menu-open"
                type="button"
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              >
                <Icon icon="burger" />
              </button>
            )}
          </div>
        </div>
      </header>
      <div className={isMobileMenuOpen ? 'mob-menu open' : 'mob-menu'}>
        <div className="mob-menu__header">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a className="header-logo">
            <Logo />
          </a>
          <div className="mob-menu__bar">
            <Languages />
            <button className="btn mob-menu__close" type="button" onClick={() => setIsMobileMenuOpen(false)}>
              <Icon icon="close" />
            </button>
          </div>
        </div>
        <div className="mob-menu__main">
          <nav className="main-menu">
            <ul>
              <li>
                <a href="#distribution" onClick={() => setIsMobileMenuOpen(false)}>
                  {intl.formatMessage({ id: 'HEADER_TOKENOMICS_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.coingecko} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_COINGECKO_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.everWallet} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_EVER_WALLET_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.adaever} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_BRIDGE_LINK_TEXT' })}
                </a>
              </li>
              <li>
                <a target="_blank" href={links.octus} rel="noreferrer">
                  {intl.formatMessage({ id: 'HEADER_OCTUS_BRIDGE_LINK_TEXT' })}
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="mob-menu__footer">
          <a href={links.dexada} target="_blank" className="btn btn--primery btn--md" rel="noreferrer">
            {intl.formatMessage({ id: 'HEADER_BUTTON' })}
          </a>
        </div>
      </div>
    </>
  );
}

export default Header;

import React, { createContext, useState } from 'react';

type Props = { children?: React.ReactNode };
type AppContext = {
  lang: string;
  setLang: (lang: string) => void;
};

export const LanguageContext = createContext<AppContext>({
  lang: '한국어',
  setLang: () => undefined,
});

// eslint-disable-next-line react/function-component-definition
const LanguageProvider: React.FC<Props> = ({ children }) => {
  const storeLang = localStorage.getItem('lang');
  const [lang, setLang] = useState<string>(storeLang || '한국어');

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    lang,
    setLang,
  };

  return <LanguageContext.Provider value={contextValue}>{children}</LanguageContext.Provider>;
};

export default LanguageProvider;

import React from 'react';
import { useIntl } from 'react-intl';
import Tokenomics from '../tokenomics/Tokenomics';

function Planned(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="planned" style={{ paddingBottom: '40px' }}>
      <div className="container">
        <div className="planned__wrap">
          <div className="planned__content">
            <h2
              className="sec-title"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'PLANNED_SECTION_TITLE',
                }),
              }}
            />
            <p className="planned__txt">{intl.formatMessage({ id: 'PLANNED_TEXT' })}</p>
          </div>
          <div className="planned__roadmap">
            <div className="planned__item">
              <h3 className="planned__item-title">{intl.formatMessage({ id: 'PLANNED_ROADMAP_FIRST_ITEM_TITLE' })}</h3>
              <ul>
                <li>{intl.formatMessage({ id: 'PLANNED_ROADMAP_FIRST_ITEM_POINT_ONE' })}</li>
                <li>{intl.formatMessage({ id: 'PLANNED_ROADMAP_FIRST_ITEM_POINT_TWO' })}</li>
              </ul>
            </div>
            <div className="planned__item">
              <h3 className="planned__item-title">{intl.formatMessage({ id: 'PLANNED_ROADMAP_SECOND_ITEM_TITLE' })}</h3>
            </div>
            <div className="planned__item">
              <h3 className="planned__item-title">{intl.formatMessage({ id: 'PLANNED_ROADMAP_THIRD_ITEM_TITLE' })}</h3>
            </div>
            <div className="planned__item">
              <h3 className="planned__item-title">{intl.formatMessage({ id: 'PLANNED_ROADMAP_FOURTH_ITEM_TITLE' })}</h3>
              <div className="planned__item-sub">
                {intl.formatMessage({ id: 'PLANNED_ROADMAP_FOURTH_ITEM_POINT_ONE' })}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Tokenomics />
      <div className="hero__bar" style={{ marginTop: '40px' }}>
        <a href="https://app.dexada.io/" target="_blank" className="btn btn--primery btn--lg" rel="noreferrer">
          {intl.formatMessage({ id: 'HERO_BUTTON_START_EARNING' })}
        </a>
      </div>
    </section>
  );
}

export default Planned;

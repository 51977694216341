import React, { useContext } from 'react';
import { IntlProvider } from 'react-intl';
import Header from './component/header/Header';
import Hero from './component/hero/Hero';
import { LanguageContext } from './providers/LanguageProvider';
import { LOCALES } from './i18n/locales';
import { messages } from './i18n/messages';
import { noop } from './utils/noop';
import Intro from './component/intro/Intro';
import Icons from './component/Icons/Icons';
import Planned from './component/planned/Planned';
import Everscale from './component/everscale/Everscale';
import Comparison from './component/comparison/Comparison';
import Interoperability from './component/interoperability/Interoperability';
import Instruction from './component/instruction/Instruction';
import Banner from './component/banner/Banner';
import FAQ from './component/faq/FAQ';
import Footer from './component/footer/Footer';
import Tokenomics from './component/tokenomics/Tokenomics';

function Main() {
  const { lang } = useContext(LanguageContext);
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <IntlProvider
      key="intl"
      locale={LOCALES[lang]}
      defaultLocale={LOCALES.ko}
      messages={messages[LOCALES[lang]]}
      onError={noop}
    >
      <div className="wrapper">
        <Header />
        <main className="main">
          <Hero />
          <Intro />
          <Icons />
          <Planned />
          <Everscale />
          <Comparison />
          <Interoperability />
          <Instruction />
          <Banner />
          <FAQ />
        </main>
        <Footer />
      </div>
    </IntlProvider>
  );
}

export default Main;

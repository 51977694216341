/* eslint-disable react/jsx-no-target-blank,react/jsx-no-useless-fragment */
import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../common/Icon';

function Instruction(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="instruction" id="instruction">
      <div className="instruction__header">
        <div className="container">
          <h2 className="sec-title">{intl.formatMessage({ id: 'INSTRUCTION_SECTION_TITLE' })}</h2>
        </div>
      </div>
      <div className="instruction__main">
        <section className="instruction-sec">
          <div className="container">
            <div className="instruction-sec__wrap">
              <div className="instruction-sec__content">
                <div className="instruction-sec__header">
                  <div className="instruction-sec__numb">
                    <Icon icon="svg1" />
                  </div>
                  <h3 className="instruction-sec__title">{intl.formatMessage({ id: 'INSTRUCTION_STEP_ONE_TITLE' })}</h3>
                </div>
                <ul className="instruction-sec__list">
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_ONE_POINT_ONE',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_ONE_POINT_TWO',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_ONE_POINT_THREE',
                      }),
                    }}
                  />
                  <li>
                    {intl.formatMessage({ id: 'INSTRUCTION_STEP_ONE_POINT_FOUR' })}
                    <span
                      className="red"
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: 'INSTRUCTION_STEP_ONE_POINT_FOUR_WARNING',
                        }),
                      }}
                    />
                  </li>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_ONE_POINT_FIVE',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_ONE_POINT_SIX',
                      }),
                    }}
                  />
                </ul>
              </div>
              <div className="instruction-sec__video">
                <div className="video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/0qellgpECR8"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="instruction-sec">
          <div className="container">
            <div className="instruction-sec__wrap">
              <div className="instruction-sec__content">
                <div className="instruction-sec__header">
                  <div className="instruction-sec__numb">
                    <Icon icon="svg2" />
                  </div>
                  <h3 className="instruction-sec__title">{intl.formatMessage({ id: 'INSTRUCTION_STEP_TWO_TITLE' })}</h3>
                </div>
                <ul className="instruction-sec__list">
                  <li>
                    {intl.formatMessage({ id: 'INSTRUCTION_STEP_TWO_POINT_ONE' })}
                    <span className="red">{intl.formatMessage({ id: 'INSTRUCTION_STEP_TWO_POINT_ONE_WARNING' })}</span>
                  </li>
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_TWO',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_THREE',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_FOUR',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_FIVE',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_SIX',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_SEVEN',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_TWO_POINT_EIGHT',
                      }),
                    }}
                  />
                </ul>
              </div>
              <div className="instruction-sec__video">
                <div className="video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/u4MGJPfFpFY"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="instruction-sec">
          <div className="container">
            <div className="instruction-sec__wrap">
              <div className="instruction-sec__content">
                <div className="instruction-sec__header">
                  <div className="instruction-sec__numb">
                    <Icon icon="svg3" />
                  </div>
                  <h3 className="instruction-sec__title">
                    {intl.formatMessage({ id: 'INSTRUCTION_STEP_THREE_TITLE' })}
                  </h3>
                </div>
                <ul className="instruction-sec__list">
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_THREE_POINT_ONE',
                      }),
                    }}
                  />
                  <li
                    dangerouslySetInnerHTML={{
                      __html: intl.formatMessage({
                        id: 'INSTRUCTION_STEP_THREE_POINT_TWO',
                      }),
                    }}
                  />
                  <li>{intl.formatMessage({ id: 'INSTRUCTION_STEP_THREE_POINT_THREE' })}</li>
                </ul>
              </div>
              <div className="instruction-sec__video">
                <div className="video">
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/hxqbFBDEds4"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
}

export default Instruction;

import React from 'react';
import { useIntl } from 'react-intl';
import { links } from '../../constants/links';

function Banner(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="banner">
      <div className="container">
        <div className="banner__wrap">
          <div className="banner__main">
            <h2 className="banner__title">{intl.formatMessage({ id: 'BANNER_TITLE' })}</h2>
            <p className="banner__txt">{intl.formatMessage({ id: 'BANNER_TEXT' })}</p>
          </div>
          <div className="banner__bar" style={{ width: 'auto' }}>
            <a href={links.telegram} target="_blank" className="btn btn--primery btn--lg" rel="noreferrer">
              {intl.formatMessage({ id: 'BANNER_BUTTON_TEXT' })}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner;

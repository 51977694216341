/* eslint-disable */
export default {
  HEADER_TOKENOMICS_LINK_TEXT: '토큰경제',
  HEADER_COINGECKO_LINK_TEXT: '코인게코 수록 EVER 정보',
  HEADER_EVER_WALLET_LINK_TEXT: 'EVER 월릿',
  HEADER_BRIDGE_LINK_TEXT: 'ADAEVER 브리지',
  HEADER_OCTUS_BRIDGE_LINK_TEXT: '옥터스 브리지',
  HEADER_BUTTON: '덱스에이다 이용하기',

  HERO_TITLE: '덱스에이다에서 최대<br>200%에&nbsp;이르는&nbsp; 연간수익의&nbsp; 이자농사를 경험하세요</br>',
  HERO_TEXT: '효과적인 카르다노 자산 관리, 이제 덱스에이다에서 모두 할 수 있습니다.',
  HERO_BUTTON_START_EARNING: '덱스에이다 이용하기',
  HERO_BUTTON_HOW_TO_EARN: '사용안내',

  INTRO_FIRST_PARAGRAPH:
    '덱스에이다는 카르다노 생태계의 신뢰 가능한 탈중앙화 거래소로서, 카르다노 자산의 유동성을 촉진하고 다른 체인의 유동성 흐름도 혼합하여 참여자의 이익에 기여합니다.',
  INTRO_SECOND_PARAGRAPH:
    '유명 개발자인 오컴(Occam.fi)과 브록서스(Broxus)에 의해 설계된 이 탈중앙화 거래소는 에버스케일 블록체인의 기술적 이점을 십분 활용합니다.',
  INTRO_lIST_FIRST_ITEM:
    '무한확장이 가능한 에버스케일의 인프라를 통해 최고의 사용자 경험과 블록체인간의 상호 운용성을 제공, 선도적인 레이어1 네트워크의 유동성 흐름을 관리할 수 있습니다.',
  INTRO_lIST_SECOND_ITEM:
    '우리의 개발 로드맵은 연결 옵션에 해당하는 제품군을 확장, 유저경험의 품질을 극대화하고 최초의 크로스 체인 유동성 DAO를 만드는 것을 포함합니다.',
  INTRO_lIST_THIRD_ITEM:
    '덱스에이다는 디파이 (DeFi) 제품군으로 다양한 블록체인을 넘나들며 활용 가능한 카르다노-에버스케일 브릿지를 제공합니다.',

  ICONS_SECTION_TITLE: '제품 특징',
  ICONS_FIRST_ITEM_TITLE: '효과적 일드 파밍',
  ICONS_FIRST_ITEM_TEXT: '3개 이상의 가상자산 종류',
  ICONS_SECOND_ITEM_TITLE: '매우 빠른 가상자산간 스왑',
  ICONS_SECOND_ITEM_TEXT: '4초 미만의 최종성(finality)',
  ICONS_THIRD_ITEM_TITLE: '블록체인간 유동성 스왑',
  ICONS_THIRD_ITEM_TEXT: '6개 이상의 블록체인 지원',
  ICONS_FOURTH_ITEM_TITLE: '멀티파밍 가능',
  ICONS_FOURTH_ITEM_TEXT: '동시에 다수 파밍 옵션',
  ICONS_FIFTH_ITEM_TITLE: '교차 풀 라우팅',
  ICONS_FIFTH_ITEM_TEXT: ' 최적의 가격 제공',
  ICONS_SIXTH_ITEM_TITLE: '고유토큰 생성가능',
  ICONS_SEVENTH_ITEM_TITLE: '간편한 트레이딩 인터페이스',
  ICONS_EIGHTH_ITEM_TITLE: '다중서명(멀티시그) 지갑 지원',

  PLANNED_SECTION_TITLE: '이후 추가될 기능</span>',
  PLANNED_TEXT:
    '덱스에이다의 근본적 초점은 시스템을 단계별로 더욱 더 탈중앙화하고 보안 및 UX를 강화하는 데에 있습니다. ',
  PLANNED_ROADMAP_FIRST_ITEM_TITLE: '-DAO 운영 ',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_ONE: '팜(농장) 생성',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_TWO: '유동성 풀 생성',

  PLANNED_ROADMAP_SECOND_ITEM_TITLE: '탈중앙화거래소 자체적 거버넌스',
  PLANNED_ROADMAP_THIRD_ITEM_TITLE: '메타마스크 지원',
  PLANNED_ROADMAP_FOURTH_ITEM_TITLE: 'AMM 커브 설정',
  PLANNED_ROADMAP_FOURTH_ITEM_POINT_ONE: '예 : 안정적인 스왑 설정',

  TOKENOMICS_TITLE: '토큰 분배',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_ONE: '초기배포',
  TOKENOMICS_DISTRIBUTION_ITEM_FARMING: '파밍',
  TOKENOMICS_DISTRIBUTION_ITEM_TREASURY: '보유금고',
  TOKENOMICS_DISTRIBUTION_ITEM_TEAM: '팀',
  TOKENOMICS_DISTRIBUTION_ITEM_PRIVATE_SALE: '프라이빗 세일',
  TOKENOMICS_DISTRIBUTION_ITEM_LIQUIDITY: '유동성',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_TWO: '덱스에이다 v2 배포',
  TOKENOMICS_GRAPHIC_TITLE: 'EVA 월별 총 공급량',
  TOKENOMICS_GRAPHIC_Y_TITLE: '총 공급, %',
  TOKENOMICS_GRAPHIC_X_TITLE: '월',

  EVERSCALE_SECTION_TITLE: '에버스케일 – 카르다노를 더욱 안전하게 만들 수 있는 레이어 2 블록체인',
  EVERSCALE_FIRST_ITEM_TITLE: '탈중앙화',
  EVERSCALE_FIRST_ITEM_TEXT: '190명 넘는 독립적인 블록검증자들이 네트워크의 안정성을 보장합니다. ',

  EVERSCALE_SECOND_ITEM_TITLE: '유연성',
  EVERSCALE_SECOND_ITEM_TEXT:
    '에버스케일은 동적 멀티스레딩, 소프트 다수결 합의 및 분산 프로그래밍과 같은 고유한 속성 집합을 가지고 있으며 대단히 유연하게 작동합니다.',

  EVERSCALE_THIRD_ITEM_TITLE: '로켓의 스피드 ',
  EVERSCALE_THIRD_ITEM_TEXT:
    '에버스케일은 스마트 계약 및 탈중앙 사용자 인터페이스를 통해 세계에서 가장 많은 양의 트랜잭션을 처리할 수 있는 Ever OS라는 플랫폼을 기반으로 합니다.',

  EVERSCALE_FOUTH_ITEM_TITLE: '안정성',
  EVERSCALE_FOUTH_ITEM_TEXT: '에버스케일의 고유한 DePool 기술은 효과적인 분산화 및 보안을 유지합니다.',

  EVERSCALE_FIFTH_ITEM_TITLE: '확장성',
  EVERSCALE_FIFTH_ITEM_TEXT:
    '에버스케일 고유의 확장성은 Soft Majority Voting (SMV)프로토콜을 통해 능력주의 원칙에 기반을 둔 탈중앙 커뮤니티에 의해 적극적으로 촉진됩니다.',

  EVERSCALE_SIXTH_ITEM_TITLE: '가장 저렴한 이용수수료',
  EVERSCALE_SIXTH_ITEM_TEXT: '에버스케일에서의 토큰 트랜잭션은 카르다노 및 이더리움의 거래 비용보다 훨씬 저렴합니다.',

  COMPARISON_TABLE_TITLE: '타 블록체인과의 비교',
  COMPARISON_FIRST_COLUMN_TITLE: '블록체인',
  COMPARISON_SECOND_COLUMN_TITLE: '초당거래수 – 실제 라이브 환경',
  COMPARISON_THIRD_COLUMN_TITLE: '초당거래수 – 이론적 한계',
  COMPARISON_FOURTH_COLUMN_TITLE: '시가총액',
  ETHEREUM: '이더리움',
  BITCOIN: '비트코인',
  SOLANA: '솔라나',
  POLKADOT: '폴카닷',
  CARDANO: '카르다노',
  NEAR: 'Near',
  AVALANCHE: '아발란체',
  EVERSCALE: '에버스케일',
  ETH_2_0: '이더리움 2.0',

  INTEROPERABILITY_SECTION_TITLE: '준비된 상호운용성',
  INTEROPERABILITY_FIRST_ITEM_TITLE:
    '검증된 에버스케일의 브릿지를 통해 사용자는 유동성을 자유롭고 안전하게 이동할 수 있습니다.',
  INTEROPERABILITY_FIRST_ITEM_TEXT:
    ' 이더리움, 바이낸스 스마트 체인, 에버스케일, 팬텀 및 폴리곤부터 카르다노 네트워크에 이르기까지, 거래는 최소한의 수수료를 요구하고 훨씬 더 빠른 속도로 이뤄집니다.',
  INTEROPERABILITY_SECOND_ITEM_TITLE: '에버스케일은 상호운용성을 보유합니다',
  INTEROPERABILITY_SECOND_ITEM_TEXT:
    '이는 프로토콜의 핵심에 내장되어 있으며 카르다노와 다른 블록체인 간의 양방향 토큰 전송을 완벽하게 지원합니다.',
  INTEROPERABILITY_THIRD_ITEM_TITLE: '유동성의 효과적 경로',
  INTEROPERABILITY_THIRD_ITEM_TEXT:
    '덱스에이다의 유동성 경로는 수억개의 유동성이 카르다노 고유 자산 유동성 풀로 유입되도록 서포트하며 이는 전체 카르다노 생태계에 매우 유익합니다.',

  INSTRUCTION_SECTION_TITLE: '파밍풀을 통해 수익을 얻는 방법 안내',
  INSTRUCTION_STEP_ONE_TITLE: '카르다노에서 에버스케일로 유동성 이전 방법 안내',
  INSTRUCTION_STEP_ONE_POINT_ONE:
    '카르다노 블록체인의 “나미월릿(<a href="https://namiwallet.io/" target="_blank" rel="noreferrer">Nami wallet</a>)”을 다운받습니다.',
  INSTRUCTION_STEP_ONE_POINT_TWO:
    ' 에버스케일의 “에버 월릿  (<a href="https://l1.broxus.com/everscale/wallet" target="_blank">EVER Wallet</a>)”을 다운받습니다. ',
  INSTRUCTION_STEP_ONE_POINT_THREE:
    '<a href="https://adaever.io/" target="_blank">adaever.io  </a>를 방문합니다. 이는 카르다노와 에버스케일 간의 브릿지이며 사용자의 나미월릿, 에버월릿이 모두 이곳에 연결 가능합니다.',
  INSTRUCTION_STEP_ONE_POINT_FOUR: '전송하고자 하는 에이다 (ADA)의 양을 기입합니다.',
  INSTRUCTION_STEP_ONE_POINT_FOUR_WARNING:
    'EVER가 없으면 "Credit Gas(가스충전)"를 활성화하십시오. 이를 활성화하면 소량의 ADA가 EVER 월릿을 활성화하는 데 사용됩니다.',
  INSTRUCTION_STEP_ONE_POINT_FIVE: '"이체(Transfer)" 버튼을 눌러 유동성을 이체합니다.',
  INSTRUCTION_STEP_ONE_POINT_SIX:
    '이체가 완료된 이후 “Import”버튼을 눌러줍니다.<a href="#faq"> <br />What should I do if I did not press the button?',

  INSTRUCTION_STEP_TWO_TITLE: 'ADA/WEVER 풀에 유동성을 추가하고 LP 토큰을 받는 방법 안내',
  INSTRUCTION_STEP_TWO_POINT_ONE:
    '브릿지에서 유동성을 이체하고 나면 에버월릿 (EVER Wallet)에 EVER와 ADA를 모두 받게 됩니다.',
  INSTRUCTION_STEP_TWO_POINT_ONE_WARNING:
    '경고! 추가적인 덱스에이다 이용을 위해서 여러분의 지갑에 최소 10 EVER 이상을 보유하셔야 합니다.',
  INSTRUCTION_STEP_TWO_POINT_TWO:
    '<a target="_blank" href="https://dexada.io/pairs/0:689f80483674e7afcb8958bd3f2744402ff672040b8423ec2a0e7dadc985e947">ADA/WEVER 페어로 이동하여 "유동성 추가(Add Liquidity)" 버튼을 클릭합니다.</a>',
  INSTRUCTION_STEP_TWO_POINT_THREE:
    '"풀 연결(Connect pools)" 버튼을 클릭하여 유동성 풀에 연결합니다. EVER 월릿에서 해당 거래를 승인해 줍니다.',
  INSTRUCTION_STEP_TWO_POINT_FOUR:
    '이제 풀에 유동성을 추가할 수 있습니다. WEVER가 없는 경우 "자동 교환(Auto Exchange)" 옵션을 사용해야 합니다.<br/><br />상단 창("왼쪽")에서 ADA를 선택하고 하단 창("오른쪽")에서 WEVER를 선택한 후 "자동 교환 활성화(Enable auto exchange)" 스위치를 클릭합니다.',
  INSTRUCTION_STEP_TWO_POINT_FIVE:
    '원하는 ADA의 수량을 입력하고 WEVER 수량은 삭제합니다. 이제 "ADA 예치하기(Deposit ADA)" 버튼을 클릭하고 EVER 월릿에서 거래를 승인합니다.',
  INSTRUCTION_STEP_TWO_POINT_SIX: '"공급(Supply)" 버튼을 클릭하고 EVER 월릿에서 그를 승인합니다. ',
  INSTRUCTION_STEP_TWO_POINT_SEVEN: '파밍에 사용가능한 LP 토큰이 이제 확보됩니다.',
  INSTRUCTION_STEP_TWO_POINT_EIGHT:
    '"LP 루트 주소 (LP root address)" 근처의 링크를 클릭하면 everscan.io로 리디렉션 되는데 여기서 주소를 복사하고 EVER 월릿으로 이동하십시오. 지갑에서 "자산 선택" 버튼을 클릭한 다음 "사용자 지정 토큰"을 선택하고 이전에 복사한 주소를 삽입합니다. 이제 지갑에 LP 토큰이 표시됩니다.',

  INSTRUCTION_STEP_THREE_TITLE: 'ADA/WEVER 유동성 풀에서 파밍을 시작하세요!',
  INSTRUCTION_STEP_THREE_POINT_ONE:
    '<a href="https://dexada.io/farming" target="_blank">덱스에이다의 "파밍(Farming)" 섹션으로 이동하여 ADA/WEVER 파밍풀(farming pool)을 클릭합니다.</a>',
  INSTRUCTION_STEP_THREE_POINT_TWO:
    '녹색 "예치(Deposit)" 섹션에서 "최대(Max)" 버튼을 클릭합니다. 그를 통해 보유한 모든 LP 토큰을 추가하게 됩니다. 다시 "입금(Deposit)" 버튼 클릭합니다.',
  INSTRUCTION_STEP_THREE_POINT_THREE: '이제 ADA/WEVER 파밍 프로그램에서 성공적으로 수익을 올릴 수 있습니다. ',

  BANNER_TITLE: '여전히 질문이 있으신가요?',
  BANNER_TEXT: '우리의 소셜 채널에서 답을 찾아보세요',
  BANNER_BUTTON_TEXT: '텔레그램 그룹 방문하기',

  FAQ_TITLE: '자주 묻는 질문',
  QUESTION_ONE: '덱스에이다에 대한 추가 정보 살펴보기',
  QUESTION_TWO: 'EVER 월릿에 표시되도록 토큰 종류 추가방법',
  QUESTION_THREE: 'WEVER란 무엇이며 어떻게 얻을 수 있나요?',
  QUESTION_FOUR: '어떻게 파밍을 시작할 수 있나요?',
  QUESTION_FIVE: '나의 LP 토큰의 가치는 어디서 확인할 수 있나요?',
  QUESTION_SIX: '파밍의 수익은 언제 거둘 수 있나요?',
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useContext, useEffect } from 'react';
import { MAX_MOBILE_WIDTH } from '../constants/constant';
import { MobileContext } from '../providers/MobileProvider';

function UseResize() {
  const { setIsMobile } = useContext(MobileContext);
  useEffect(() => {
    const fn = () => {
      const htmlWidth = document?.documentElement?.clientWidth;
      const result = htmlWidth <= MAX_MOBILE_WIDTH;
      setIsMobile(result);
    };
    window.addEventListener('resize', fn);
    fn();
    return () => window.addEventListener('resize', fn);
  }, []);
  return null;
}

export default UseResize;

import React from 'react';
import { useIntl } from 'react-intl';
import { Icon } from '../common/Icon';

function Hero(): JSX.Element {
  const intl = useIntl();

  return (
    <section className="hero">
      <div className="container">
        <div className="hero__wrap">
          <div className="hero__main">
            <h1 className="hero__title">
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'HERO_TITLE',
                  }),
                }}
              />
            </h1>
            <div className="hero__txt">{intl.formatMessage({ id: 'HERO_TEXT' })}</div>
            <div className="hero__bar">
              <a href="https://app.dexada.io/" target="_blank" className="btn btn--primery btn--lg" rel="noreferrer">
                {intl.formatMessage({ id: 'HERO_BUTTON_START_EARNING' })}
              </a>
              <a href="#instruction" className="btn btn--secondary btn--lg">
                {intl.formatMessage({ id: 'HERO_BUTTON_HOW_TO_EARN' })}
              </a>
            </div>
          </div>
          <div className="hero__logotyps">
            <div className="hero__logo">
              <Icon icon="everscale" />
            </div>
            <div className="hero__logo">
              <Icon icon="cardano" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../providers/LanguageProvider';
import Dropdown from '../common/dropdown/Dropdown';
import { LANGUAGES } from '../../constants/constant';

function Languages(): JSX.Element {
  const { lang } = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleList = () => {
    setIsOpen(!isOpen);
  };

  const closeList = () => {
    setIsOpen(false);
  };
  return (
    <div className="lang">
      <div
        className="lang__main"
        onClick={toggleList}
        style={{ textTransform: 'capitalize', fontSize: '14px', zIndex: 2000 }}
      >
        {/* eslint-disable-next-line no-nested-ternary */}
        {lang === 'ja' ? '日本語' : lang === 'en' ? 'eng' : lang}
      </div>
      <Dropdown items={LANGUAGES} onCLick={closeList} isOpen={isOpen} />
    </div>
  );
}

export default Languages;

/* eslint-disable */
export default {
  HEADER_TOKENOMICS_LINK_TEXT: 'Tokenomics',
  HEADER_COINGECKO_LINK_TEXT: 'EVER on CoinGecko',
  HEADER_EVER_WALLET_LINK_TEXT: 'EVER Wallet',
  HEADER_BRIDGE_LINK_TEXT: 'ADAEVER bridge',
  HEADER_OCTUS_BRIDGE_LINK_TEXT: 'Octus Bridge',
  HEADER_BUTTON: 'Enter App',

  HERO_TITLE: 'Earn with DEXADA on <br>up&nbsp;to&nbsp;200%&nbsp;APR liquidity farming</br>',
  HERO_TEXT: 'DEXADA is Ready to Release the Liquidity Floodgates For Cardano Assets',
  HERO_BUTTON_START_EARNING: 'Start earning',
  HERO_BUTTON_HOW_TO_EARN: 'How to earn',

  INTRO_FIRST_PARAGRAPH:
    'DEXADA is a chain-agnostic DEX with a heavy focus on catalyzing the Cardano ecosystem’s liquidity and bringing liquidity flows from other chains into the mix',
  INTRO_SECOND_PARAGRAPH:
    'Being incubated by Occam.fi this DEX benefits from Everscale blockchain’s technological advantages and power of Broxus development team.',
  INTRO_lIST_FIRST_ITEM:
    'The infinitely scalable infrastructure of Everscale enables us to deliver the best possible user experience and cross-chain interoperability to bring liquidity flows from leading L1 networks together.',
  INTRO_lIST_SECOND_ITEM:
    'DEXADA is in production. Our development roadmap includes expanding our suite of connectivity options thereby maximizing the quality of the UX as well as creating the first-ever cross-chain liquidity DAO.',
  INTRO_lIST_THIRD_ITEM:
    'DEXADA is a suite of DeFi products rather than a standalone DEX. DEXADA brings a Cardano-Everscale bridge that can be leveraged to a multi-chain setting.',

  ICONS_SECTION_TITLE: 'Product Features',
  ICONS_FIRST_ITEM_TITLE: 'Native Yield Farming',
  ICONS_FIRST_ITEM_TEXT: '3+ currencies',
  ICONS_SECOND_ITEM_TITLE: 'Lightning Fast Swaps',
  ICONS_SECOND_ITEM_TEXT: '&lt;4 seconds finality',
  ICONS_THIRD_ITEM_TITLE: 'Cross-Chain Liquidity Swaps',
  ICONS_THIRD_ITEM_TEXT: '6 Blockchains Supported',
  ICONS_FOURTH_ITEM_TITLE: 'Multifarming',
  ICONS_FOURTH_ITEM_TEXT: 'Double Farming Options',
  ICONS_FIFTH_ITEM_TITLE: 'Сross-Pool Routing',
  ICONS_FIFTH_ITEM_TEXT: 'For Optimal Price',
  ICONS_SIXTH_ITEM_TITLE: 'Native Token Builder',
  ICONS_SEVENTH_ITEM_TITLE: 'Flexible TradingView Interfaces',
  ICONS_EIGHTH_ITEM_TITLE: 'Multisig Wallet Support',

  PLANNED_SECTION_TITLE: 'Planned Features <span>Post-Launch</span>',
  PLANNED_TEXT:
    'DEXADA’s focus is on developing our system step by step and improving our decentralization, security and UX as we continue to iterate.',
  PLANNED_ROADMAP_FIRST_ITEM_TITLE: 'DAO Management of',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_ONE: 'Farm Creation',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_TWO: 'Pool Creation',
  PLANNED_ROADMAP_SECOND_ITEM_TITLE: 'DEX Governance',
  PLANNED_ROADMAP_THIRD_ITEM_TITLE: 'Metamask Support',
  PLANNED_ROADMAP_FOURTH_ITEM_TITLE: 'Setting AMM curves',
  PLANNED_ROADMAP_FOURTH_ITEM_POINT_ONE: 'i.e. making stableswaps',

  TOKENOMICS_TITLE: 'Token distribution',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_ONE: 'Initial distribution',
  TOKENOMICS_DISTRIBUTION_ITEM_FARMING: 'Farming',
  TOKENOMICS_DISTRIBUTION_ITEM_TREASURY: 'Treasury',
  TOKENOMICS_DISTRIBUTION_ITEM_TEAM: 'Team',
  TOKENOMICS_DISTRIBUTION_ITEM_PRIVATE_SALE: 'Private sale',
  TOKENOMICS_DISTRIBUTION_ITEM_LIQUIDITY: 'Liquidity',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_TWO: 'Dexada v2 distribution',
  TOKENOMICS_GRAPHIC_TITLE: 'EVA total supply by month',
  TOKENOMICS_GRAPHIC_Y_TITLE: 'Total supply, %',
  TOKENOMICS_GRAPHIC_X_TITLE: 'Month',

  EVERSCALE_SECTION_TITLE: 'Get unlimited scalability of governance, community, performance and adoption',
  EVERSCALE_FIRST_ITEM_TITLE: 'Decentralized',
  EVERSCALE_FIRST_ITEM_TEXT: 'Over 190 independent validators ensure the stability of the network.',

  EVERSCALE_SECOND_ITEM_TITLE: 'Flexible',
  EVERSCALE_SECOND_ITEM_TEXT:
    ' Everscale has a unique set of properties, such as dynamic multithreading, soft majority consensus and distributed programming, which all work towards enabling it to be flexible.',

  EVERSCALE_THIRD_ITEM_TITLE: 'Rocket fas',
  EVERSCALE_THIRD_ITEM_TEXT:
    'Everscale is based on a platform called Ever OS, capable of processing millions of transactions per second, with Turing-complete smart contracts and decentralized user interfaces.',

  EVERSCALE_FOUTH_ITEM_TITLE: 'Secure',
  EVERSCALE_FOUTH_ITEM_TEXT: 'Everscale&apos;s unique DePool technology to keep it decentralized and secure.',

  EVERSCALE_FIFTH_ITEM_TITLE: 'Scalable',
  EVERSCALE_FIFTH_ITEM_TEXT:
    'The scaling technology improves on its competitors by being actively governed by a decentralized community founded upon meritocratic principles via Soft Majority Voting protocol.',
  EVERSCALE_SIXTH_ITEM_TITLE: 'Near-Zero fees',
  EVERSCALE_SIXTH_ITEM_TEXT:
    ' Token transactions on Everscale are several times cheaper than the cost of transactions in Cardano and Ethereum.',

  COMPARISON_TABLE_TITLE: 'Comparison with other blockchains',
  COMPARISON_FIRST_COLUMN_TITLE: 'Blockchain',
  COMPARISON_SECOND_COLUMN_TITLE: 'TPS - Live Environment',
  COMPARISON_THIRD_COLUMN_TITLE: 'TPS - Theoretical Limit',
  COMPARISON_FOURTH_COLUMN_TITLE: 'Market Capitalization',
  ETHEREUM: 'Ethereum',
  BITCOIN: 'Bitcoin',
  SOLANA: 'Solana',
  POLKADOT: 'Polkadot',
  CARDANO: 'Cardano',
  NEAR: 'Near',
  AVALANCHE: 'Avalanche',
  EVERSCALE: 'Everscale',
  ETH_2_0: 'Eth 2.0',

  INTEROPERABILITY_SECTION_TITLE: 'Ready-Made Interoperability',
  INTEROPERABILITY_FIRST_ITEM_TITLE: 'The Everscale Bridge allows users to move liquidity',
  INTEROPERABILITY_FIRST_ITEM_TEXT:
    ' from Ethereum, Binance Smart Chain, Everscale, Fantom and Polygon to the Cardano network, where transactions require minimal fees and take place at much higher speeds.',
  INTEROPERABILITY_SECOND_ITEM_TITLE: 'Everscale has interoperability',
  INTEROPERABILITY_SECOND_ITEM_TEXT:
    'built-in to the heart of the protocol and is fully capable of supporting bi-directional token transfers between Cardano and other blockchains.',
  INTEROPERABILITY_THIRD_ITEM_TITLE: 'Liquidity pathway',
  INTEROPERABILITY_THIRD_ITEM_TEXT:
    'This liquidity pathway is supremely beneficial for the entire Cardano Ecosystem as hundreds of millions in liquidity can easily begin flowing into the Cardano native assets liquidity pools.',
  INSTRUCTION_SECTION_TITLE: 'How to earn with farming pools',
  INSTRUCTION_STEP_ONE_TITLE: 'Transfer liquidity from Cardano to Everscale',
  INSTRUCTION_STEP_ONE_POINT_ONE:
    'Download the <a href="https://namiwallet.io/" target="_blank" rel="noreferrer"> Nami wallet</a> for Cardano blockchain',
  INSTRUCTION_STEP_ONE_POINT_TWO:
    ' Download the  <a href="https://l1.broxus.com/everscale/wallet" target="_blank"> EVER Wallet </a>for Everscale blockchain',
  INSTRUCTION_STEP_ONE_POINT_THREE:
    'Visit  <a href="https://adaever.io/" target="_blank">adaever.io  </a> — the Cardano-Everscale Bridge and connect both of your wallets',
  INSTRUCTION_STEP_ONE_POINT_FOUR: 'Type in the amount of ADAs you want to transfer.',
  INSTRUCTION_STEP_ONE_POINT_FOUR_WARNING:
    'If you do not have EVERs, then be sure to enable &quot;Credit Gas&quot;. With this enabled, a small amount of ADAs will be used to deploy your EVER Wallet.',
  INSTRUCTION_STEP_ONE_POINT_FIVE: 'Transfer liquidity by pressing the “Transfer” button',
  INSTRUCTION_STEP_ONE_POINT_SIX:
    'Press the &quot;Import&quot; button after the transfer is completed.<a href="#faq"> <br />What should I do if I did not press the button?',

  INSTRUCTION_STEP_TWO_TITLE: 'ADD liquidity to the WEVER/ADA pool and get LP tokens',
  INSTRUCTION_STEP_TWO_POINT_ONE:
    'After transferring liquidity on the bridge, you will receive both EVERs and ADAs in your EVER wallet',
  INSTRUCTION_STEP_TWO_POINT_ONE_WARNING:
    'Warning! Make sure you have at least 10 EVERs in your wallet in order to proceed with further DEX operations',
  INSTRUCTION_STEP_TWO_POINT_TWO:
    '<a target="_blank" href="https://dexada.io/pairs/0:689f80483674e7afcb8958bd3f2744402ff672040b8423ec2a0e7dadc985e947">Go to the ADA/WEVER pair and click the &quot;Add Liquidity&quot; button </a>',
  INSTRUCTION_STEP_TWO_POINT_THREE:
    'Connect to the liquidity pool by clicking the “Connect pools” button. Sign the transaction in the EVER Wallet',
  INSTRUCTION_STEP_TWO_POINT_FOUR:
    'Now you can add liquidity to the pool. If you do not have WEVERs, then you should use the “auto exchange” option.<br/><br />Choose WEVER in the upper window (“left”), ADA in the lower window (“right”) and click on the “Enable auto exchange” switch',
  INSTRUCTION_STEP_TWO_POINT_FIVE:
    'Type in the desired amount of ADAs you want to add and delete the WEVERs amount. Now click on the “Deposit ADA” button. Sign the transaction in the EVER Wallet',
  INSTRUCTION_STEP_TWO_POINT_SIX: 'Click on the &quot;Supply&quot; button. Sign the transaction in the EVER Wallet',
  INSTRUCTION_STEP_TWO_POINT_SEVEN: 'You have received LP tokens that are used for liquidity farming',
  INSTRUCTION_STEP_TWO_POINT_EIGHT:
    'Click on the link near “LP root address”. After being redirected ton the everscan.io copy the address and go to EVER Wallet. In the wallet click “select assets” button, then choose “custom token” and insert the previously copied address. Now you will see LP tokens in your wallet.',

  INSTRUCTION_STEP_THREE_TITLE: 'Start farming with the WEVER/ADA liquidity pool!',
  INSTRUCTION_STEP_THREE_POINT_ONE:
    ' Go to the <a href="https://dexada.io/farming" target="_blank"> &quot;Farming&quot; section of the DEX </a> and click on the ADA/WEVER farming pool',
  INSTRUCTION_STEP_THREE_POINT_TWO:
    'In the green &quot;Deposit&quot; section, click on the “Max” button. Thus, you will add all of your LP tokens. Click on the “Deposit” button',
  INSTRUCTION_STEP_THREE_POINT_THREE: 'Now you are succesfully earning in the ADA/WEVER farming program',

  BANNER_TITLE: 'Still have questions?',
  BANNER_TEXT: 'Join our Telegram group to find answers to all of your questions',
  BANNER_BUTTON_TEXT: 'Visit our Telegram group',

  FAQ_TITLE: 'FAQ',
  QUESTION_ONE: 'Answers to the Frequently Asked Questions about Dexada decentralized exchange',
  QUESTION_TWO: 'How to add tokens to display in my EVER Wallet?',
  QUESTION_THREE: 'What is a WEVER and how can I get it?',
  QUESTION_FOUR: 'How do I start farming?',
  QUESTION_FIVE: 'Where can I see the value of my LP tokens?',
  QUESTION_SIX: 'When will I get my farming&nbsp;rewards?',
};

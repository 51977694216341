/* eslint-disable */
export default {
  HEADER_TOKENOMICS_LINK_TEXT: 'Tokenomics',
  HEADER_COINGECKO_LINK_TEXT: 'CongeckoでのEVERについて',
  HEADER_EVER_WALLET_LINK_TEXT: 'エバーウォレット',
  HEADER_BRIDGE_LINK_TEXT: 'ブリッジ',
  HEADER_OCTUS_BRIDGE_LINK_TEXT: 'Octus Bridge',
  HEADER_BUTTON: 'コミュニティに参加する',

  HERO_TITLE: '最大200％に達する年<br>間収益のファーミング</br>',
  HERO_TEXT: '効果的なカルダノ資産、いますぐデックスエイダで利用可能',
  HERO_BUTTON_START_EARNING: 'Start earning',
  HERO_BUTTON_HOW_TO_EARN: 'How to earn',

  INTRO_FIRST_PARAGRAPH:
    'デックスエイダはカルダノ経済圏の信頼できるDEXです。 カルダノだけでなく、さまざまなチェーンの流動性の流れを混ぜて資産保有者の利益に貢献します。',
  INTRO_SECOND_PARAGRAPH:
    '有名な開発者であるOccam.fiとBroxusによって設計されたこのDEXは、Everscaleブロックチェーンの技術的利点を十分に活用しています。',
  INTRO_lIST_FIRST_ITEM:
    '拡張性が無限大のEverscaleのインフラにより、最高のユーザーエクスペリエンスとブロックチェーン間の相互運用性を提供し、大手レイヤ1ネットワークの流動性フローをまとめることができます。',
  INTRO_lIST_SECOND_ITEM:
    '私たちの開発ロードマップには、接続オプション一式を拡張し、それによってUXの品質を最大化するとともに、史上初のクロスチェーン流動性DAOを作成することが含まれています',
  INTRO_lIST_THIRD_ITEM:
    'デックスエイダは、さまざまなブロックチェーンを通過し、利用可能なカルダノ - Everscaleブリッジを提供します。',

  ICONS_SECTION_TITLE: '製品の特徴',
  ICONS_FIRST_ITEM_TITLE: '効果的な収益ファーミング',
  ICONS_FIRST_ITEM_TEXT: '3種類以上の仮想資産',
  ICONS_SECOND_ITEM_TITLE: '非常に高速な仮想資産間スワップ',
  ICONS_SECOND_ITEM_TEXT: '4秒未満の最終性(finality)',
  ICONS_THIRD_ITEM_TITLE: 'ブロックチェーン間の流動性スワップ',
  ICONS_THIRD_ITEM_TEXT: '6つ以上のブロックチェーンをサポート',
  ICONS_FOURTH_ITEM_TITLE: 'マルチファーミング',
  ICONS_FOURTH_ITEM_TEXT: 'ダブルファーミングオプション',
  ICONS_FIFTH_ITEM_TITLE: 'クロスプールルーティング',
  ICONS_FIFTH_ITEM_TEXT: '最適な価格を提供',
  ICONS_SIXTH_ITEM_TITLE: 'ネイティブトークンビルダー',
  ICONS_SEVENTH_ITEM_TITLE: '柔軟な取引インターフェース',
  ICONS_EIGHTH_ITEM_TITLE: 'マルチシグウォレットのサポート',

  PLANNED_SECTION_TITLE: '以降に追加する機能</span>',
  PLANNED_TEXT:
    'デックスエイダの根本的な焦点は、システムを段階的にさらに一元化し、セキュリティとUXを強化することです。',
  PLANNED_ROADMAP_FIRST_ITEM_TITLE: '- DAO運営',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_ONE: 'ファーム（農場）生成',
  PLANNED_ROADMAP_FIRST_ITEM_POINT_TWO: '流動性プールの作成',

  PLANNED_ROADMAP_SECOND_ITEM_TITLE: 'DEXの独自のガバナンス',
  PLANNED_ROADMAP_THIRD_ITEM_TITLE: 'メタマスクサポート',
  PLANNED_ROADMAP_FOURTH_ITEM_TITLE: 'AMMカーブ設定',
  PLANNED_ROADMAP_FOURTH_ITEM_POINT_ONE: '例: 安定したスワップ設定',

  TOKENOMICS_TITLE: 'Token distribution',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_ONE: 'Initial distribution',
  TOKENOMICS_DISTRIBUTION_ITEM_FARMING: 'Farming',
  TOKENOMICS_DISTRIBUTION_ITEM_TREASURY: 'Treasury',
  TOKENOMICS_DISTRIBUTION_ITEM_TEAM: 'Team',
  TOKENOMICS_DISTRIBUTION_ITEM_PRIVATE_SALE: 'Private sale',
  TOKENOMICS_DISTRIBUTION_ITEM_LIQUIDITY: 'Liquidity',
  TOKENOMICS_DISTRIBUTION_ITEM_TITLE_TWO: 'Dexada v2 distribution',
  TOKENOMICS_GRAPHIC_TITLE: 'EVA total supply by month',
  TOKENOMICS_GRAPHIC_Y_TITLE: 'Total supply, %',
  TOKENOMICS_GRAPHIC_X_TITLE: 'Month',

  EVERSCALE_SECTION_TITLE: 'Everscale - カルダノをより安全にすることができるレイヤー2ブロックチェーン',
  EVERSCALE_FIRST_ITEM_TITLE: '脱中央化',
  EVERSCALE_FIRST_ITEM_TEXT: '190以上の独立したバリデータがネットワークの信頼性を保証します。',

  EVERSCALE_SECOND_ITEM_TITLE: '柔軟性',
  EVERSCALE_SECOND_ITEM_TEXT:
    ' Everscaleには、動的マルチスレッド、ソフトマジョリティコンセンサス、分散プログラミングなどの独自のプロパティセットがあり、これらはすべて、柔軟に機能します。',

  EVERSCALE_THIRD_ITEM_TITLE: 'ロケットのスピード',
  EVERSCALE_THIRD_ITEM_TEXT:
    'Everscaleは、スマートコントラクトと脱中央ユーザーインターフェースを介して世界で最も大量のトランザクションを処理できるEver OSというプラットフォームに基づいています。',

  EVERSCALE_FOUTH_ITEM_TITLE: '安全性',
  EVERSCALE_FOUTH_ITEM_TEXT: 'EverScaleのユニークなDePoolテクノロジーは、効果的な脱中央化とセキュリティを維持します。',

  EVERSCALE_FIFTH_ITEM_TITLE: '拡張性',
  EVERSCALE_FIFTH_ITEM_TEXT:
    'EverScaleのユニークなスケーラビリティは、Soft Majority Voting（SMV）プロトコルを通じて、能力主義の原則に基づいた脱中央コミュニティによって積極的に促進されます。',

  EVERSCALE_SIXTH_ITEM_TITLE: '最も安い利用料',
  EVERSCALE_SIXTH_ITEM_TEXT:
    'Everscaleでのトークントランザクションは、カルダノとイーサリアムの取引コストよりもはるかに安いです。',

  COMPARISON_TABLE_TITLE: '他のブロックチェーンとの比較',
  COMPARISON_FIRST_COLUMN_TITLE: 'ブロックチェーン',
  COMPARISON_SECOND_COLUMN_TITLE: '1秒あたりの取引数 - 実際のライブ環境',
  COMPARISON_THIRD_COLUMN_TITLE: '1秒当たりの取引数 – 理論的限界',
  COMPARISON_FOURTH_COLUMN_TITLE: '時価総額',
  ETHEREUM: 'イーサリアム',
  BITCOIN: 'ビットコイン',
  SOLANA: 'ソラナ',
  POLKADOT: 'ポルカドット',
  CARDANO: 'カルダノ',
  NEAR: 'ニア',
  AVALANCHE: 'アバランチェ',
  EVERSCALE: 'エバースケール',
  ETH_2_0: 'イーサリアム 2.0',

  INTEROPERABILITY_SECTION_TITLE: '既製の相互運用性',
  INTEROPERABILITY_FIRST_ITEM_TITLE: '実績のあるEverscaleのブリッジにより、ユーザーは流動的に移動できます。',
  INTEROPERABILITY_FIRST_ITEM_TEXT:
    'イーサリアム、バイナンススマートチェーン、エバースケール、ファントム、ポリゴンからカルダノネットワークまで、取引は最小限の手数料を必要とし、超高速スピードで行われます。',
  INTEROPERABILITY_SECOND_ITEM_TITLE: 'Everscaleは優れた相互運用性を持っています。',
  INTEROPERABILITY_SECOND_ITEM_TEXT:
    'これはプロトコルの中心に組み込まれており、カルダノと他のブロックチェーン間の双方向トークン転送を完全にサポートします。',
  INTEROPERABILITY_THIRD_ITEM_TITLE: '流動性の効果的な経路',
  INTEROPERABILITY_THIRD_ITEM_TEXT:
    'デックスエイダの流動性経路は、数億の流動性がカルダノネイティブ資産の流動性プールに容易に流入するようにサポートするので、カルダノ経済圏全体にとって非常に有益です。',

  INSTRUCTION_SECTION_TITLE: 'ファーミングプールで収益を得る方法',
  INSTRUCTION_STEP_ONE_TITLE: 'カルダノからエバースケールへの流動性移行方法',
  INSTRUCTION_STEP_ONE_POINT_ONE:
    'カルダノブロックチェーンの「ナミウォレット(<a href="https://namiwallet.io/" target="_blank" rel="noreferrer">Nami wallet</a>)」をダウンロードします。',
  INSTRUCTION_STEP_ONE_POINT_TWO:
    ' Everscaleの「エバーウォレット(<a href="https://l1.broxus.com/everscale/wallet" target="_blank">EVER Wallet</a>)」をダウンロードしてください。',
  INSTRUCTION_STEP_ONE_POINT_THREE:
    ' <a href="https://adaever.io/" target="_blank">adaever.io  </a> —  Cardano-Everscale Bridgeにアクセスして、両方のウォレットを接続します 。ユーザーのナミウォレットとエバーウォレットの両方がここに接続可能です。',
  INSTRUCTION_STEP_ONE_POINT_FOUR: '転送したいエイダ（ADA）の量を記入します。',
  INSTRUCTION_STEP_ONE_POINT_FOUR_WARNING:
    ' EVERがない場合は、「Credit Gas（ガス充電）」を有効にしてください。 これを有効にすると、少量のADAがエバーウォレットを有効にするために使用されます。',
  INSTRUCTION_STEP_ONE_POINT_FIVE: 'Transfer」ボタンを押して流動性を移転します。',
  INSTRUCTION_STEP_ONE_POINT_SIX:
    '転送が完了したら、「インポート」ボタンを押します。\n<a href="#faq"> <br />What should I do if I did not press the button?',
  INSTRUCTION_STEP_TWO_TITLE: 'ADA/WEVERプールに流動性を追加し、LPトークンを受け取る方法',
  INSTRUCTION_STEP_TWO_POINT_ONE: 'ブリッジから流動性を転送した後、エバーウォレットでEVERとADAの両方を受け取ります。',
  INSTRUCTION_STEP_TWO_POINT_TWO:
    '<a target="_blank" href="https://dexada.io/pairs/0:689f80483674e7afcb8958bd3f2744402ff672040b8423ec2a0e7dadc985e947">ADA/WEVERペアに移動し、「流動性の追加(Add Liquidity」ボタンをクリックします。</a>',
  INSTRUCTION_STEP_TWO_POINT_ONE_WARNING:
    '警告！さらにDEX操作を続行するには、ウォレットに少なくとも10EVER以上あることが必要です。',
  INSTRUCTION_STEP_TWO_POINT_THREE:
    '「プールの接続(Connect pools)」ボタンをクリックして、流動性プールに接続します。 エバーウォレットでその取引を承認します。',
  INSTRUCTION_STEP_TWO_POINT_FOUR:
    'これでプールに流動性を追加できます。 WEVERがない場合は、「自動交換（Auto Exchange）」オプションを使用する必要があります。<br/><br />上部ウィンドウ（「左」）でADAを選択し、下部ウィンドウ（「右」）でWEVERを選択し、「自動交換を有効にする（Enable auto exchange）」スイッチをクリックします。',
  INSTRUCTION_STEP_TWO_POINT_FIVE:
    '希望のADAの数量を入力し、WEVER数量を削除します。 「ADAをデプロイする」ボタンをクリックして、エバーウォレットで取引を承認します。',
  INSTRUCTION_STEP_TWO_POINT_SIX: '[Supply]ボタンをクリックして、エバーウォレットでトランスザクションを承認します。',
  INSTRUCTION_STEP_TWO_POINT_SEVEN: 'ファーミングに使用可能なLPトークンが取得されます。',
  INSTRUCTION_STEP_TWO_POINT_EIGHT:
    '「LPルートアドレス」の近くのリンクをクリックすると、everscan.ioにリダイレクトされます。ここでアドレスをコピーしてエバーウォレットに移動します。 ウォレットの「アセットの選択」ボタンをクリックし、「カスタムトークン」を選択し、以前にコピーしたアドレスを挿入します。 これでウォレットにLPトークンが表示されます。',

  INSTRUCTION_STEP_THREE_TITLE: 'ADA / WEVER流動性プールでファーミングを始めましょう！',
  INSTRUCTION_STEP_THREE_POINT_ONE:
    '<a href="https://dexada.io/farming" target="_blank">デックスエイダの「ファーミング」セクションに移動し、ADA / WEVERファーミングプールをクリックします。</a>',
  INSTRUCTION_STEP_THREE_POINT_TWO:
    '緑色の「予備（Deposit）」セクションで、「最大（Max）」ボタンをクリックします。 彼はあなたが持っていたすべてのLPトークンを追加します。 もう一度「デポジット」ボタンをクリックします。',
  INSTRUCTION_STEP_THREE_POINT_THREE: 'これにより ADA / WEVERファーミングプログラムで収益を上げることができます。',

  BANNER_TITLE: '他に質問がありますか？',
  BANNER_TEXT: '私たちのテレグラムグループに参加して答えを見つけてください。',
  BANNER_BUTTON_TEXT: 'テレグラムグループにアクセス',

  FAQ_TITLE: 'よくある質問',
  QUESTION_ONE: 'デックスエイダの詳細情報を見る',
  QUESTION_TWO: 'EVERウォレットに表示されるようにトークンタイプを追加する方法?',
  QUESTION_THREE: 'とは何ですか？どのように入手できますか？',
  QUESTION_FOUR: 'ファーミングはどのように開始できますか？',
  QUESTION_FIVE: '私のLPトークンの価値はどこで確認できますか？',
  QUESTION_SIX: 'ファーミングの報酬をいつ受け取ることができますか？',
};

import React from 'react';
import { useIntl } from 'react-intl';

function Comparison(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="comparison">
      <div className="container">
        <h2 className="sec-title">{intl.formatMessage({ id: 'COMPARISON_TABLE_TITLE' })}</h2>
        <div className="table-wrap">
          <table className="table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'COMPARISON_FIRST_COLUMN_TITLE' })}</th>
                <th>{intl.formatMessage({ id: 'COMPARISON_SECOND_COLUMN_TITLE' })}</th>
                <th>{intl.formatMessage({ id: 'COMPARISON_THIRD_COLUMN_TITLE' })}</th>
                <th>{intl.formatMessage({ id: 'COMPARISON_FOURTH_COLUMN_TITLE' })}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>{intl.formatMessage({ id: 'ETHEREUM' })}</th>
                <td>25</td>
                <td>100,000 + ({intl.formatMessage({ id: 'ETH_2_0' })})</td>
                <td>$520,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'BITCOIN' })}</th>
                <td>7</td>
                <td>–</td>
                <td>$1,150,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'SOLANA' })}</th>
                <td>1,900</td>
                <td>60,000</td>
                <td>$76,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'POLKADOT' })}</th>
                <td>1,700</td>
                <td>100,000</td>
                <td>$50,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'CARDANO' })}</th>
                <td>250</td>
                <td>-</td>
                <td>$65,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'NEAR' })}</th>
                <td>2.6</td>
                <td>4,000</td>
                <td>$5,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'AVALANCHE' })}</th>
                <td>2.4</td>
                <td>100,000</td>
                <td>$17,000,000,000</td>
              </tr>
              <tr>
                <th>{intl.formatMessage({ id: 'EVERSCALE' })}</th>
                <td>64,000</td>
                <td>10,000,000</td>
                <td>$250,000,000</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default Comparison;

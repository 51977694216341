import React from 'react';
import { useIntl } from 'react-intl';

function Intro(): JSX.Element {
  const intl = useIntl();
  return (
    <section className="intro">
      <div className="container">
        <div className="intro__wrap">
          <div className="intro__col intro__txt">
            <p>{intl.formatMessage({ id: 'INTRO_FIRST_PARAGRAPH' })}</p>
          </div>
          <ul className="intro__col intro__list">
            <li>{intl.formatMessage({ id: 'INTRO_lIST_FIRST_ITEM' })}</li>
            <li>{intl.formatMessage({ id: 'INTRO_lIST_SECOND_ITEM' })}</li>
            <li>{intl.formatMessage({ id: 'INTRO_lIST_THIRD_ITEM' })}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Intro;
